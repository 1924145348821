import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';

const GlobalPayPage = React.lazy(() => import('./Components/GlobalPay'));
const FailurePage = React.lazy(() => import('./Components/FinalScreens/Failure'));
const SuccessPage = React.lazy(() => import('./Components/FinalScreens/Success'));
const ProcessingPage = React.lazy(() => import('./Components/FinalScreens/Processing'));
const RedirectionPage = React.lazy(() => import('./Components/ConnectorRedirection'));
const SkywayRedirect=React.lazy(() => import('./Components/SkywayRedirect'));

function App() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/payment-success" exact element={<SuccessPage />}/>
          <Route path="/payment-failure" exact element={<FailurePage />} />
          <Route path="/payment-processing" exact element={<ProcessingPage />} />
          <Route path="/global-pay/:token" exact element={<GlobalPayPage />} />
          <Route path="/payment-redirection" exact element={<RedirectionPage />} />
          <Route path="/skyway-payment-redirect" exact element={<SkywayRedirect />} />
          <Route path="/" exact element={<GlobalPayPage />} />
          <Route path="/*" exact element={<GlobalPayPage />} />
          {/* <Route path="/test-provider/:id" exact element={<RecoveryCode />} /> */}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
